import React from 'react'
import './yoyaku.css'

const sub = document.getElementById("sub");

function func1() {
    sub.style.width = sub.contentWindow.document.body.scrollWidth + "px";
    sub.style.height = sub.contentWindow.document.body.scrollHeight + "px";
}

const Yoyaku = () => {
    return (
        <div className="container yoyaku py-3">
            <div className="row justify-content-center">
                <div class="caution">
                    <p>●お申込みは、カレンダー上のご希望のレッスン日時をクリックしてください。<br />
                    ●確定ののち、氏名、電話、メールアドレス等をご記入の上、お申込みください。<br />
                    ※会員様が取得済みのコースは、体験カレンダーから入力する必要はありません。</p>
                </div>
                <iframe  id="yoyakuframe" name="yoyakuframe" title="yoyakuframe" width="100%" onload="func1()" src="https://select-type.com/rsv/?id=X-GeNiKO298&c_id=157970&w_flg=1" frameBorder={0} />
            </div>
            <div className="container">
                <div className="justify-content-center lesson">
                    <h3 class="lesson__lesson_title">電話で予約する場合</h3>
                    <p class="lesson__lesson_name">お気軽にご連絡ください。<br />
                        <span class="telling">TEL：<a href="tel:0369131377">03-6913-1377</a></span><br />
                        留守番電話での対応になる場合がございます。<br />メッセージをお残しください。<br />後ほどご連絡いたします。</p>
                </div>
                <div className="justify-content-center lesson">
                <h3 class="lesson__lesson_title">体験レッスンで準備するもの</h3>
                    <p class="lesson__lesson_name">
                    レッスンの始まる15分前には、<br class="responsive--sxs" />スタジオにお越しください。<br />
                    お着替えされる方は更衣室がございます。</p>
                </div>
                <div className="justify-content-center lesson">
                <h3 class="lesson__lesson_title">持ち物</h3>
                    <p class="lesson__lesson_name">
                    〇Tシャツ・スウエットなど<br class="responsive--sxs" />動きやすい楽な服装をご用意ください。<br />
                    〇汗拭きタオル　〇水分補給の飲料</p>
                </div>
                <div className="justify-content-center lesson">
                    <p class="lesson__lesson_name">レッスンで利用するヨガマットは<br class="responsive--sxs" />無料でレンタルできます。<br />
                    <br class="responsive--sxs" />
                    ※持ち物など分からない場合は<br class="responsive--sxs" />お気軽にお問合せください。</p>
                </div>
            </div>
        </div>
    )
}

export default Yoyaku
