import React from 'react'
import './Footer.css'
// import { Button } from './Button'
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ContactIcon from './ContactIcon';

/*
<div className='social-icons'>
        <a href="https://www.instagram.com/studio_viita/" 
        className='social-icon-link instagram'
        target='_blank'
        aria-label='Instagram'
        >
        <i className='fab fa-instagram' />
        </a>
    </div>
*/

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
      }
    return (
        <div className='footer-container'>
            <ContactIcon />
            <section className='footer-subscription'>
                
            </section>
            <div className='footer-links'>
                <a href="tel" className="telbtn">TEL</a>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <div className='footer-logo'>
                            <Link to='/' className='social-logo' onClick={toggleHome}>
                            <img src="images/footer_logo.png" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className='footer-link-items'>
                        <h2>レッスン</h2>
                        <Link to='/yoga'>ヨーガ</Link>
                        <Link to='/hula'>フラダンス</Link>
                        <Link to='/walk'>エクササイズウォーキング</Link>
                        <Link to='/foul'>フーレセラピー</Link>
                        <Link to='/senioryoga'>シニアの方へ</Link>
                        <Link to='/trial'>体験予約</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>その他</h2>
                        <Link to='/sitemap'>サイトマップ</Link>
                        {/* <Link to='/'>利用規約</Link>
                        <Link to='/'>プライバシーポリシー</Link> */}
                        <Link to='/contact'>アクセス・お問い合わせ</Link>
                    </div>
                </div>
            </div>
            <section className='social-media'>
                <div className='social-media-wrap justify-content-center'>
                    <small className='website-rights'>STUDIO VIITA © All Rights Reserved.2021</small>
                </div>
            </section>
        </div>
    )
}

export default Footer
