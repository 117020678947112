import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import 'react-tabs/style/react-tabs.css';
import '../Senioryoga.css';
import '../Gmap.css';
import Yoyaku from '../Yoyaku';
import MyComponent from '../Gmap';

import { useEffect, useRef, useState } from "react";

const FadeInImage = ({ src, alt, direction = "up" }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // 一度表示されたら監視を解除
        }
      },
      { threshold: 0.3 } // 画像の20%が表示されたら発火
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="image-container">
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        className={`fade-in-${direction} ${isVisible ? "show" : ""}`}
      />
    </div>
  );
};

export default function Hula(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap" rel="stylesheet" />

            <section id="syoga">
                <section id="syoga__top">
                    <div className="container">
                        <div id="syoga__top__txt">
                            <p className='font-size--l'>senior yoga</p>
                            <h2 className='font-size--xxl'>シニア世代の皆さま<br className='responsive--lll' />「<span className='bold'>心と体に優しいヨーガ</span>を始めてみませんか」</h2>
                        </div>
                        <video 
                            autoPlay 
                            muted 
                            loop 
                            playsInline
                        >
                            <source src="./video/video_syoga_top3.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </section>
                
                <section id="syoga__about" className='section'>
                    <div className='about__wrap__txt'>
                        <h2 class=" justify-content-center font-size--xl about__txt">スタジオヴィータでは、<span className='bold'>シニアの皆さまが</span><span className='bold orange'>安心してヨーガを始められる環境</span>を提供しています。</h2>
                    </div>   
                    <div>
                        <FadeInImage src="./images/img_about_image.jpg" alt="私達と一緒にヨーガをしましょう！" direction="up" />
                    </div>   
                </section>

                <section id="syoga__naze" className="bg--blightorange section">
                    <div className="container">
                        <h2 className="font-size--xxl text_center">なぜシニアの方に<span className='bold orange'><br className='responsive--m' />ヨーガがオススメ</span>なのでしょうか？</h2>
                        <div className="syoga__naze__wrap">
                            <div className="inwrap">
                                <FadeInImage src="./images/img_why_image1.png" alt="体の柔軟性やバランスを向上" direction="down" />
                                <h3 className='font-size--l bold'>1. 体の柔軟性やバランスを無理なく向上させます。</h3>
                                <p className='font-size--m'>ヨーガは激しい動きではなく、ゆっくりとした動きで筋肉を伸ばし、体を調えることができます。無理のないポーズで、少しずつ体を柔らかくしていきますので、初めての方でも安心です。</p>
                            </div>
                            <div className="inwrap">
                                <FadeInImage src="./images/img_why_image2.png" alt="心もリラックス" direction="down" />
                                <h3 className='font-size--l bold'>2. 心のリラックスもサポートします。</h3>
                                <p className='font-size--m'>ヨーガは、心を落ちつかせるための呼吸法や瞑想も含まれています。日常のストレスや不安を少しずつ取り除き、心身ともにリフレッシュすることができます。</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="syoga__reason" className='section'>
                    <div className="">
                        <div id="reason__top">
                            <h2 className="font-size--xxl bold text_center">スタジオヴィータには<span className='orange'>選ばれる理由</span>があります。</h2>
                            <p className='font-size--l'>年齢を重ねることで感じる体の変化や運動への不安は、シニアになれば誰にでもあるものです。<span className='orange'>私たちのヨーガ教室では、そんな気持ちにも寄り添い</span>無理なく楽しく体を動かすことができます。</p>
                        </div>
                        <p className='borderline_s'></p>
                        <div className="inwrap reason__clmn reason__clmn--rev">
                            <div className='reason__clmn--l'>
                                <div className=' reason__clmn__txt reason__clmn__txt--2row'>
                                    <h3 className="font-size--xl">お客さまの年齢は60代～80代。<span className='orange'>シニアのペースでヨーガを始められます</span>。</h3>
                                    <p className="font-size--m">若い方が多かったり慣れた方が多いとレッスンについていけないかもと、気にされるかも知れませんが、ヴィータではご自身のペースでヨーガを楽しむことができます。</p>
                                    <h3 className="font-size--xl"><span className='orange'>初心者でも安心！</span>一人一人に寄り添います。 </h3>
                                    <p className="font-size--m">経験豊富なインストラクターが、最初にお客さまのお体の状態やお悩み事をお聞きしますので、一人ひとりの体の状態に合わせた指導を行います。</p>
                                </div>
                            </div>
                            <div className='reason__clmn--r reason__clmn__img'>
                                <FadeInImage src="./images/img_reason_image1.jpg" alt="シニアのペースで始められ、初心者でも安心" direction="left" />
                            </div>
                        </div>
                        <div className="inwrap reason__clmn">
                            <div className='reason__clmn--l'>
                                <div className=' reason__clmn__txt'>
                                    <h3 className="font-size--xl"><span className='orange'>60代以上のインストラクター</span>が担当します。</h3>
                                    <p className="font-size--m">インストラクターがシニアの方と同世代以上ですので、皆さまの気持ちに寄り添い、無理のない範囲でゆっくりと進めていくため、初めてでも安心してご参加いただけます。</p>
                                </div>
                            </div>
                            <div className='reason__clmn--r reason__clmn__img'>
                                <FadeInImage src="./images/img_reason_image2.jpg" alt="インストラクターは60代以上" direction="right" />
                            </div>
                        </div>
                        <div className="inwrap reason__clmn reason__clmn--rev">
                            <div className='reason__clmn--l'>
                                <div className=' reason__clmn__txt'>
                                    <h3 className="font-size--xl">リラックスできる<span className='orange'>温かな空間</span>。</h3>
                                    <p className="font-size--m">シニアの皆さまにとって「安心して過ごせる場所」となることを目指しています。明るく開放的な空間で心が和みます。</p>
                                </div>
                            </div>
                            <div className='reason__clmn--l reason__clmn__img'>
                                <FadeInImage src="./images/img_reason_image3.jpg" alt="温かな空間" direction="left" />
                            </div>
                        </div>
                        <div className="inwrap reason__clmn">
                            <div className='reason__clmn--l'>
                                <div className=' reason__clmn__txt'>
                                    <h3 className="font-size--xl"><span className='orange'>駅近</span>のスタジオ。</h3>
                                    <p className="font-size--m">西武池袋線の石神井公園駅南口改札を出たロータリーに面した便利な立地。通いやすさ抜群で、シニアの方も安心して通えるスタジオです。</p>
                                </div>
                            </div>
                            <div className='reason__clmn--r reason__clmn__img'>
                                <FadeInImage src="./images/img_reason_image4.jpg" alt="スタジオは駅チカ" direction="right" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="syoga__saa" className='section'>
                    <div className='container'>
                        <h2 className="text_center font-size--xxl">さあ、健康なシニアライフへ踏み出しましょう！</h2>
                        <p className='font-size--l'>ヨーガは、年齢に関係なく、いつからでも始めることができます。ヨーガの実習は、一生続けられる健康法です。年齢や運動が得意、不得意に関係なく、シニアの体や心の状態に寄り添いながらサポートします。あなたのペースに合わせて、無理なく続けて、私たちと一緒に楽しく健康に年を重ねましょう。体験レッスンは随時受付中ですので、まずはお気軽にお問い合わせください。</p>
                    </div>
                    <div className='bg--white'>
                        <div className='container'>
                            <FadeInImage src="./images/img_saa_image.jpg" alt="健康なシニアライフへ踏み出しましょう" direction="up" />
                        </div>
                    </div>
                </section>

                <section id="syoga__message" className="bg--blightorange section">
                    <div className="container">
                        <h2 className="font-size--xxl text_center">スタジオヴィータからシニアの皆様へのメッセージ</h2>
                    </div>
                    <div id='message__wrap' className="container">
                        <div className='message__wrap__txt'>
                            <p className='font-size--l regular'>ヨーガは幸せに生きるための道だと思っています。<br />３年後、５年後、１０年後の自分を想像できますか？<br />シニアの時期を健やかに迎え、過ごすためにはヨーガを日常に取り入れ、継続することをお勧めします。</p>
                            <div className='message__wrap__img--s responsive--sxs'>
                                <FadeInImage src="./images/img_message_image1.jpg" alt="ヨーガを日常に取り入れ、継続することをお勧めします" direction="up" />
                            </div>
                            <p className='font-size--l regular'>身体を使ったアーサナ（体操）で姿勢の歪みが改善し柔軟性が生まれると、血行が良くなり臓器の働きはもちろん自律神経がととのいます。さらに呼吸法と瞑想による、自分自身と向き合うエクササイズで、身体の不調や心の状態に早く気づくことができ、健康で充実した暮らしを永く送れるようになるでしょう。</p>
                            <div className='message__wrap__img--s responsive--sxs'>
                                <FadeInImage src="./images/img_message_image2.jpg" alt="健康で充実した暮らしを永く送れるようになります" direction="up" />
                                <p className='font-size--s text-center'>最高齢インストラクター93歳</p>
                                </div>
                            <p className='font-size--l regular'>身体と呼吸と心は切り離せないものです。この3つを上手く調和させ、バランスをとり、年齢を重ねても心と身体は若々しくありたいものです。</p>
                            <p className='font-size--l regular text_right'>Studio Viita</p>
                        </div>
                        <div className='message__wrap__img responsive--lllm'>
                            <FadeInImage src="./images/img_message_image1.jpg" alt="ヨーガを日常に取り入れ、継続することをお勧めします" direction="left" />
                            <FadeInImage src="./images/img_message_image2.jpg" alt="健康で充実した暮らしを永く送れるようになります" direction="left" />
                            <p className='font-size--s text-center'>最高齢インストラクター93歳</p>
                        </div>
                    </div>
                </section>
                <div className='section'>
                <section id="syoga__prof" className="container">
                    <h2 className='font-size--xxl text_center bold'>代表の紹介</h2>
                    <div className='sub__wrap'>
                        <div className='message__wrap__txt'>
                            <p className='font-size--m'>STUDIO VIITA</p>
                            <h3>KAGARI<span>（カガリ）</span></h3>
                            <div className='responsive--sxs'>
                                <FadeInImage src="./images/img_yoga_inst_kagari.jpg" alt="STUDIO VIITA代表 KAGARI" direction="up" />
                            </div>
                            <h4 className='bold'>プロフィール</h4>
                            <p className='font-size--m bold'>&lt;経歴&gt;</p>
                            <p className='font-size--m'>1987年　日本ヨーガ禅道友会に入門<br />
                            1989年　健康ヨーガ教室に入門<br />
                            1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
                            1994年～1997年　NHKカルチャーセンター青山教室にて指導<br />
                            1997年北インド　ヒマラヤにてヨーガ研修<br />
                            2000年　インド　ロナワラ　カイバリヤダーマヨーガ大学にて短期研修を受講<br />
                            2000年　日本ヨーガ禅道友会の教師に認定<br />
                            2002年～スポーツプレックス代々木店店舗、コナミスポーツクラブ、フージャースウェルネス＆スポーツアリス店舗にて指導<br />
                            2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
                            2015年よりパドマの会主宰<br />
                            2025年4月より朝日カルチャーセンター新宿にて金曜日夜の『佐保田ヨーガ』の講師を担当</p>
                        </div>
                        <div className='prof__wrap__img responsive--lllm'>
                            <FadeInImage src="./images/img_yoga_inst_kagari.jpg" alt="STUDIO VIITA代表 KAGARI" direction="left" />
                        </div>
                    </div>
                </section>

                <section id="syoga__book" className="container">
                    <h2 className='font-size--xxl text_center bold'>冊子の紹介</h2>
                    <div className='sub__wrap'>
                        <div className='message__wrap__txt'>
                            <h3 className='font-size--xxl'>スタジオ・ヴィータでは、日本ヨーガ禅道友会の認定講師が指導をしています。</h3>
                            <div className='responsive--sxs'>
                                <FadeInImage src="./images/img_book_yogazen.jpg" alt="代表のKAGARIも寄稿している冊子『道友』" direction="up" />
                            </div>
                            <p className='font-size--m'>『道友』は1973年に創刊された日本ヨーガ禅道友会の会員会報誌で、年二回の発行です。<br />創設者の故佐保田鶴治先生の禅話をはじめ、ヨーガを学んでいると出会う『ヨーガ・スートラ』の講義の連載や『バガヴァッド・ギータ』の講義なども掲載されています。</p>
                        </div>
                        <div className='book__wrap__img responsive--lllm'>
                            <FadeInImage src="./images/img_book_yogazen.jpg" alt="代表のKAGARIも寄稿している冊子『道友』" direction="left" />
                        </div>
                    </div>
                </section>
                </div>
                <h3 className="subtitle">体験レッスン申込み専用カレンダー</h3>
                <Yoyaku />
            </section>
            <section id="syoga__btm">
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}