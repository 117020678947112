import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home'
import Yoga from './components/pages/Yoga';
import Hula from './components/pages/Hula';
import Walk from './components/pages/Walk';
import Foul from './components/pages/Foul';
import Contact from './components/pages/Contact';
import Senioryoga from './components/pages/Senioryoga';
import Schedule from './components/pages/Schedule';
import Trial from './components/pages/Trial';
import SignUp from './components/pages/SignUp';
import Sitemap from './components/pages/Sitemap';
import Topics from './components/pages/Topics';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
        <Navbar />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/yoga" component={Yoga} />
            <Route exact path="/hula" component={Hula} />
            <Route exact path="/walk" component={Walk} />
            <Route exact path="/foul" component={Foul} />
            <Route exact path="/schedule" component={Schedule} />
            <Route exact path="/senioryoga" component={Senioryoga} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/sitemap" component={Sitemap} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/topics" component={Topics} />
            <Route exact path="/trial" component={Trial} />
      </Switch>
    </>    
  );
}

export default App;
